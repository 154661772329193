import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import PhoneFieldInput from "../inputs/PhoneFieldInput";
import { COUNTRIES, phoneValidationWithCountryExtension } from "../form.utils";
import Typography from "../../../common/atoms/Typography";
import InputFull from "../../../common/atoms/InputFull";
import { FileInput } from "../inputs/FileInput";
import WebpageFieldInput from "../inputs/WebpageFieldInput";

const MAX_SIZE_BYTE = 150;
const BYTE_MULTIPLIER = 1024 * 1024; 

const validations = {
  email: (v) => {
    if (!v) {
      return "El correo es requerido";
    } else {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
        ? ""
        : "El correo es inválido";
    }
  },
  address: (v) => {
    if (!v) {
      return "La dirección es requerido";
    } else {
      return "";
    }
  },
  webpage: (v) => {
    if (!v) {
      return "El enlace a su página web es requerido";
    } else {
      return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        v
      )
        ? ""
        : "El enlace es inválido.";
    }
  },
  pdf: (v, url) => {
    if (!v) {
      return url === "" ? "La presentación de empresa es requerida" : "";
    } else {
      if (v.size > MAX_SIZE_BYTE * BYTE_MULTIPLIER) {
        return `El archivo no debe superar los ${MAX_SIZE_BYTE}MB`;
      }
      return v.type.localeCompare("application/pdf") !== 0
        ? "El archivo debe tener formato .pdf"
        : "";
    }
  },
  businessPresentations: (v, url) => {
    if (!v) {
      return url === "" ? "La presentación de empresa es requerida" : "";
    } else {
      if (v.size > MAX_SIZE_BYTE * BYTE_MULTIPLIER) {
        return `El archivo no debe superar los ${MAX_SIZE_BYTE}MB`;
      }
      return v.type?.localeCompare("application/pdf") === 0 ||
        v.type?.localeCompare("video/mp4") === 0
        ? ""
        : "El archivo debe tener formato .pdf o .mp4";
    }
  },
};

const webpageValueValidation = (v) => {
  if (!v) {
    return "El enlace a su página web es requerido";
  } else {
    return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      v
    )
      ? ""
      : "El enlace es inválido.";
  }
};

const webpageTagValidation = (v) => {
  return v ? "" : "El enlace debe tener una etiqueta";
};

const phoneValueValidation = (v, country) => {
  if (!v) {
    return "El teléfono es requerido";
  } else if (country !== "") {
    return phoneValidationWithCountryExtension(v, country);
  } else {
    return "";
  }
};

const phoneTagValidation = (v) => {
  return v ? "" : "El teléfono debe tener una etiqueta";
};

const GeneralContactSection = forwardRef(
  (
    {
      initialData,
      currentCompany,
      readOnly,
      mode,
      isOwnCard,
      onChange,
      onError,
    },
    ref
  ) => {
    const [formData, setFormData] = useState({
      phones: initialData.phones,
      email: initialData.email,
      address: initialData.address || undefined,
      webpage: initialData.webpage || undefined,
      webpages: initialData.webpages || undefined,
      pdf: initialData.pdf || undefined,
      businessPresentations: initialData.businessPresentations || undefined,
    });
    const [errors, setErrors] = useState({});
    const [enabledEmailInput, setEnabledEmailInput] = useState(false);

    const validatePhoneValue = (value, country, index) => {
      const error = phoneValueValidation(value, country);

      setErrors((prevState) => ({
        ...prevState,
        [`phones${index}value`]: error,
      }));

      return error;
    };

    const validateWebpageValue = (value, index) => {
      const error = webpageValueValidation(value);

      setErrors((prevState) => ({
        ...prevState,
        [`webpages${index}value`]: error,
      }));

      return error;
    };

    const validateWebpageTag = (value, index) => {
      const error = webpageTagValidation(value);

      setErrors((prevState) => ({
        ...prevState,
        [`webpages${index}tag`]: error,
      }));
    };

    const validatePhoneTag = (value, index) => {
      const error = phoneTagValidation(value);

      setErrors((prevState) => ({
        ...prevState,
        [`phones${index}tag`]: error,
      }));

      return error;
    };

    const validateFileInput = (type, name, value, fileType = "file") => {
      const field = formData[name];
      let currentError = "";
      if (fileType === "link") {
        currentError =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            value
          )
            ? ""
            : "El enlace es inválido";
      } else {
        currentError = validations[name](value, field.url);
      }

      setErrors((prevState) => ({
        ...prevState,
        [name]: currentError,
      }));

      return currentError;
    };

    const validateMultipleFileInput = (
      type,
      name,
      value,
      index,
      fileType = "file"
    ) => {
      const field = formData[name][index];
      let currentError = "";
      if (fileType === "link") {
        currentError =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            value
          )
            ? ""
            : "El enlace es inválido";
      } else {
        currentError = validations[name](value, field.url);
      }

      setErrors((prevState) => ({
        ...prevState,
        [`${name}${index}`]: currentError,
      }));

      return currentError;
    };

    const validateInput = (name, value) => {
      const error = validations[name](value);
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }));

      return error;
    };

    const handleWebpageInputChange = (category, index, value) => {
      const webpages = formData.webpages;

      switch (category) {
        case "value":
          webpages[index] = { ...webpages[index], value: value };
          validateWebpageValue(value, index);
          break;
        case "tag":
          webpages[index] = { ...webpages[index], tag: value };
          validateWebpageTag(value, index);
          break;
      }

      setFormData((prevState) => ({
        ...prevState,
        webpages,
      }));
    };

    const handlePhoneInputChange = (category, index, value) => {
      const phones = formData.phones;

      switch (category) {
        case "value":
          phones[index] = { ...phones[index], value: value };
          validatePhoneValue(value, phones[index].country, index);
          break;
        case "tag":
          phones[index] = { ...phones[index], tag: value };
          validatePhoneTag(value, index);
          break;
        case "country":
          phones[index] = {
            ...phones[index],
            country: value,
            extension: COUNTRIES[value].secondary,
          };
          break;
      }

      setFormData((prevState) => ({
        ...prevState,
        phones,
      }));
    };

    const handleInputChange = (e, type) => {
      let { name, value } = e.target;

      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      validateInput(name, value);
    };

    const handleDeleteArrayInput = (e, type, name, index) => {
      const list = formData[name];
      list.splice(index, 1);
      setFormData((prevState) => ({
        ...prevState,
        [name]: list,
      }));

      if (name === "webpages") {
        setErrors((prevState) => ({
          ...prevState,
          [`webpages${index}value`]: "",
          [`webpages${index}tag`]: "",
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [`${name}${index}`]: "",
        }));
      }

      if (name === "phones") {
        setErrors((prevState) => ({
          ...prevState,
          [`phones${index}value`]: "",
          [`phones${index}tag`]: "",
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [`${name}${index}`]: "",
        }));
      }
    };

    const handleDeleteInput = (e, type, name) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

      setFormData((prevState) => {
        const { [name]: _, ...rest } = prevState;
        return rest;
      });
    };

    // const handleOnKeyDownPhone = (e) => {
    //   if (e.target.type === "number") {
    //     const symbols = ["e", "E", "+", "-", "."];
    //     if (symbols.includes(e.key)) {
    //       e.preventDefault();
    //     }
    //   }
    // };

    const handleOnKeyDownPhone = (e) => {
      const key = e.key;
      const isNumber = /^[0-9]$/;
      const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
      if (!isNumber.test(key) && !allowedKeys.includes(key)) {
        e.preventDefault();
      }
    };

    const handleFileInputChange = (e, type) => {
      const name = e.target.name;
      const field = { ...formData[name] };
      if (e.target.files) {
        const value = e.target.files[0];
        field.value = value;
        field.filename = value.name;
        validateFileInput(type, name, value, "file");
      } else if (e.target.value) {
        field.externalUrl = e.target.value;
        validateFileInput(type, name, field.externalUrl, "link");
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: field,
      }));
    };

    const handleMultipleFileInputChange = (e, type, index) => {
      const name = e.target.name;
      const list = [...formData[name]];
      const field = list[index];
      if (e.target.files) {
        const value = e.target.files[0];
        list[index] = { ...list[index], value, filename: value.name };
        validateMultipleFileInput(type, name, value, index, "file");
      } else if (e.target.value) {
        list[index] = { ...list[index], externalUrl: e.target.value };
        validateMultipleFileInput(type, name, field.externalUrl, index, "link");
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: list,
      }));
    };

    const validateAll = () => {
      let isValid = true;
      Object.keys(formData).forEach((name) => {
        if (formData[name] === undefined) return;
        let error = "";
        if (name === "webpages") {
          const webpagesErrors = [];
          formData[name].forEach((webpage, index) => {
            const valueError = validateWebpageValue(webpage.value, index);
            const tagError = validateWebpageTag(webpage.tag, index);
            webpagesErrors.push(valueError || tagError);
          });
          error = webpagesErrors.some((item) => item);
        } else if (name === "phones") {
          const phoneErrors = [];
          formData[name].forEach((phone, index) => {
            const valueError = validatePhoneValue(
              phone.value,
              phone.country,
              index
            );
            const tagError = validatePhoneTag(phone.tag, index);
            phoneErrors.push(valueError || tagError);
          });
          error = phoneErrors.some((item) => item);
        } else if (name === "pdf") {
          let pdfError = "";
          if (!formData.pdf) return;
          if (formData.pdf.externalUrl) {
            pdfError = validateFileInput(
              null,
              name,
              formData.pdf.externalUrl,
              "link"
            );
          } else {
            pdfError = validateFileInput(
              null,
              name,
              formData.pdf.value,
              "file"
            );
          }
          error = !!pdfError;
        } else if (name === "businessPresentations") {
          const businessPresentationsErrors = [];
          if (!formData[name]) return;
          formData[name].forEach((bp, index) => {
            let bpError = "";
            if (formData.businessPresentations[index].externalUrl) {
              bpError = validateMultipleFileInput(
                null,
                name,
                formData.businessPresentations[index].externalUrl,
                index,
                "link"
              );
            } else {
              bpError = validateMultipleFileInput(
                null,
                name,
                formData.businessPresentations[index].value,
                index,
                "file"
              );
            }
            businessPresentationsErrors.push(bpError);
          });
          error = businessPresentationsErrors.some((item) => item);
        } else {
          error = validateInput(name, formData[name]);
        }
        if (error) {
          isValid = false;
        }
      });
      return isValid;
    };

    const addField = (name, category, field) => {
      let currentField = formData[name];
      if (field.type === "array") {
        if (!currentField) {
          currentField = [];
        }
        currentField.push(field.object);
      } else {
        if (!currentField) {
          currentField =
            name === "webpage" ? currentCompany.webUrl : field.object;
        }
      }
      setFormData((prevState) => ({ ...prevState, [name]: currentField }));
    };

    useEffect(() => {
      if (mode === "creation") {
        setEnabledEmailInput(!isOwnCard);
      } else if (mode === "edition") {
        setEnabledEmailInput(false);
      }
    }, [isOwnCard, mode]);

    useEffect(() => {
      const initialErrors = validateAll();
      onError(initialErrors);
    }, []);

    useEffect(() => {
      onError(errors);
    }, [errors]);

    useEffect(() => {
      onChange(formData);
    }, [formData]);

    useImperativeHandle(ref, () => ({
      validateAll,
      addField,
    }));

    return (
      <>
        <p id="datosContacto" className="form-subtitle mt-2">
          Datos de contacto
        </p>
        <div className="alert alert-secondary d-flex gap-2" role="alert">
          <i className="bi bi-exclamation-circle-fill"></i>
          <span>
            <b>Nota:</b> Se utilizará el código de país del primer teléfono
            ingresado para el número de WhatsApp.
          </span>
        </div>
        <Typography variant="tiny" weight="bold">
          <b>Teléfonos</b>
        </Typography>
        {formData.phones.map((phone, index) => {
          return (
            <PhoneFieldInput
              autoFocus={phone.value === ""}
              key={`phones-${index}`}
              extra={index > 0}
              valueError={errors[`phones${index}value`]}
              valueHelperText={errors[`phones${index}value`]}
              tagError={errors[`phones${index}tag`]}
              tagHelperText={errors[`phones${index}tag`]}
              valueLabel={`Teléfono ${index + 1}`}
              onChange={handlePhoneInputChange}
              name="phones"
              valueOfElement={phone.value}
              tagOfElement={phone.tag}
              countryOfElement={phone.country}
              disabledValue={readOnly}
              disabledTag={readOnly}
              position={index}
              deleteInputField={handleDeleteArrayInput}
              onKeyDown={handleOnKeyDownPhone}
            />
          );
        })}
        <InputFull
          error={errors.email}
          errorMessage={errors.email}
          label="Correo"
          name="email"
          fullWidth
          isDisabled={!enabledEmailInput || readOnly}
          variant="standard"
          value={formData.email}
          onChange={handleInputChange}
        />
        {formData.address !== undefined && (
          <InputFull
            error={errors.address}
            errorMessage={errors.address}
            label="Dirección"
            name="address"
            fullWidth
            isDisabled={readOnly}
            variant="standard"
            value={formData.address}
            extra={true}
            deleteInputField={handleDeleteInput}
            onChange={handleInputChange}
          />
        )}
        {formData.webpage !== undefined && (
          <InputFull
            error={errors.webpage}
            errorMessage={errors.webpage}
            label="Página web"
            name="webpage"
            fullWidth
            isDisabled={readOnly}
            variant="standard"
            value={formData.webpage}
            extra={true}
            deleteInputField={handleDeleteInput}
            onChange={handleInputChange}
          />
        )}
        <Typography variant="tiny" weight="bold" className="pt-2">
          <b>Sitios Web</b>
        </Typography>
        {formData.webpages.map((webpage, index) => {
          return (
            <WebpageFieldInput
              autoFocus={webpage.value === ""}
              key={`webpages-${index}`}
              extra={index > 0}
              valueError={errors[`webpages${index}value`]}
              valueHelperText={errors[`webpages${index}value`]}
              tagError={errors[`webpages${index}tag`]}
              tagHelperText={errors[`webpages${index}tag`]}
              valueLabel={`Web ${index + 1}`}
              onChange={handleWebpageInputChange}
              name="webpages"
              valueOfElement={webpage.value}
              tagOfElement={webpage.tag}
              disabledValue={readOnly}
              disabledTag={readOnly}
              position={index}
              deleteInputField={handleDeleteArrayInput}
            />
          );
        })} 
        {formData.pdf !== undefined && (
          <FileInput
            error={errors.pdf}
            helperText={errors.pdf}
            label="Presentación PDF"
            deleteInputField={handleDeleteInput}
            name="pdf"
            value={formData.pdf}
            onChange={handleFileInputChange}
            onBlur={() => {}}
            extra={true}
            acceptedType="application/pdf"
          />
        )}
        {formData.businessPresentations &&
          Array.isArray(formData.businessPresentations) &&
          formData.businessPresentations.map((bp, index) => {
            return (
              <FileInput
                key={`businessPresentations${index}`}
                error={errors[`businessPresentations${index}`]}
                helperText={errors[`businessPresentations${index}`]}
                label="Presentación Empresa"
                deleteInputField={(e, type, name) =>
                  handleDeleteArrayInput(e, type, name, index)
                }
                name="businessPresentations"
                value={bp}
                onChange={(e, type) =>
                  handleMultipleFileInputChange(e, type, index)
                }
                onBlur={() => {}}
                extra={true}
                acceptedType="application/pdf,video/mp4"
              />
            );
          })}
      </>
    );
  }
);

export default GeneralContactSection;
