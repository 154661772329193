import { getSocialNetworkInfoByName } from "./form.utils";

export const getContactLabel = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    return contactItem["tag"];
  }
  if (contactKey === "email") {
    return "Correo";
  }
  if (contactKey === "webpage") {
    return "Web Empresa";
  }
  if (contactKey === "pdf") {
    return "PDF";
  }
  if (contactKey === "businessPresentations") {
    return "Video";
  }
  if (contactKey === "address") {
    return "Dirección";
  }
  if (contactKey === "webpages") {
    return contactItem["tag"];
  }
  return "";
};

export const getContactStaticValue = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    const phoneNumber = contactItem["value"];
    const extension = contactItem["extension"] || "";
    return phoneNumber.startsWith("9") ? `${extension} ${phoneNumber}` : phoneNumber;
}
  if (contactKey === "email") {
    return contactItem;
  }
  if (contactKey === "webpage") {
    return contactItem;
  }
  if (contactKey === "webpages") {
    return contactItem["value"];
  }
  if (contactKey === "pdf") {
    return contactItem["filename"] || "Presentación Empresa";
  }
  if (contactKey === "businessPresentations") {
    return contactItem["filename"] || "Presentación Empresa";
  }
  if (contactKey === "address") {
    return contactItem;
  }
  return "";
};

export const getContactValue = (contactItem, contactKey) => {
  if (contactKey === "phones") {
    const phoneNumber = contactItem["value"];
    const extension = contactItem["extension"] || "";
    const formattedNumber = phoneNumber.startsWith("9") ? `${extension}${phoneNumber}` : phoneNumber;
    return `tel:${formattedNumber}`;
  }
  if (contactKey === "webpages") {
    return contactItem["value"].includes("http://") ||
      contactItem["value"].includes("https://")
      ? contactItem["value"]
      : `https://${contactItem["value"]}`;
  }
  if (contactKey === "email") {
    const email = encodeURIComponent(contactItem);
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    const mailtoLink = `mailto:${contactItem}`;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) return mailtoLink;
    else return gmailLink;
  }
  if (contactKey === "webpage") {
    return contactItem.includes("http://") || contactItem.includes("https://")
      ? contactItem
      : `https://${contactItem}`;
  }
  if (contactKey === "pdf") {
    const finalUrl = contactItem["externalUrl"] || contactItem["url"] || "";
    return finalUrl.includes("http://") || finalUrl.includes("https://")
      ? finalUrl
      : `https://${finalUrl}`;
  }
  if (contactKey === "businessPresentations") {
    const finalUrl = contactItem["externalUrl"] || contactItem["url"] || "";
    return finalUrl.includes("http://") || finalUrl.includes("https://")
      ? finalUrl
      : `https://${finalUrl}`;
  }
  if (contactKey === "address") {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Dispositivo iOS
      return `https://maps.apple.com/?q=${contactItem}`;
    } else {
      // Otros dispositivos (por defecto Google Maps)
      return `https://www.google.com/maps/search/${contactItem}`;
    }
  }
  return "";
};

export const getSocialValue = (socialItem, socialKey) => {
  const data = getSocialNetworkInfoByName(socialKey);
  return `https://${data.baseUrl}${socialItem}`;
};
